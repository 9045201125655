import React, { useEffect, useState } from 'react'
import Layout from './../components/layout'
import SEO from './../components/seo'
import { graphql } from 'gatsby'

const AboutPage = props => {
    const aboutPageData = props.data.allContentfulBio.edges[0].node
    const [ showLogo, setLogo ] = useState(true)


    const checkLogo = e => {
        window.innerWidth <= 991 ? setLogo(false) : setLogo(true)
    }

    useEffect(() => {
        checkLogo()
        window.addEventListener('resize',  checkLogo)
    })

    return (
        <Layout logoActive={showLogo}>
            <SEO 
            keywords={[`gatsby`, `react`, `javascript`, `corona`, `google`, `liftmaster`, `ameriprise`, `node`, `express`, `front end developer`, `Africa American`, `berato`]}
                title='About me'
                description="Berato Wilson is a Minnesota-based Senior Front End Engineer with over 8 years of experience. He's done work for The University of Minnesota, The University of California - Berkeley, Corona Beer, Post Cereal, and Google. He's used frameworks like React, Angular, Meteor and Vue to name a few."
            />
            <section className='about_me'>
                <div className="bio__image col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <img src={`${aboutPageData.bioPicture.file.url}`} className="berato" alt="Berato Wilson" />
                </div>
                <div className="bio col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="container">
    <h1 className="bio__name">{aboutPageData.name}</h1>
                        <h2 className="bio__title">
                            {aboutPageData.title}
                        </h2>
                        <div className="bio__description" dangerouslySetInnerHTML={{__html: aboutPageData.description.childContentfulRichText.html}} />
                    </div>
                </div>

            </section>

        </Layout>
    )
}

export const aboutQuery = graphql`
query MyQuery {
  allContentfulBio(limit: 1, filter: {name: {eq: "Yer Berato Wilson"}}) {
    edges {
      node {
        title
        name
        bioPicture {
          file {
            url
          }
          description
        }
        description {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
}
`

export default AboutPage
